<template>
  <div class="row">

    <div class="col-md-12">

      <Card :title="'Kalender Penggunaan Ruangan'">
        <template v-slot:body>
          <CalendarMode />
        </template>
      </Card>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CalendarMode from '@/component/appointments/RoomUsageCalendarMode.vue'

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    CalendarMode,
    Treeselect
  },

  mounted() {    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penggunaan Ruangan", route: "" },
      { title: "Kalender" },
    ])
  },

}
</script>